// --- ANIMATED BANNER HEADING ---
// --- DATA POINT CAROUSEL ---
// --- MOBILE MENU OVERLAY ---
// --- PICTURE ELEMENT HTML5 SHIV ---
// --- IMAGE TEXT BLOCK DESIGN PATTERN GRID ---
// --- CARD GRID ---
// --- TABS ---
// --- TABS ACCORDION ---
// --- PUBLISHED TIME ---
// --- LINK RULES ---
// --- COOKIES ---

// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------
// --- ANIMATED BANNER HEADING ---
// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------

// feature removed
/* function animateHeadline() {
    $(".animate-slide").animatedHeadline({
        animationType: "push"
    })
}
animateHeadline(), $(".banner__inner-heading.ah-headline").removeClass("ah-headline-pre-load"), $(".ah-words-wrapper b:last-child").bind("DOMSubtreeModified", function(e) {
    setTimeout(function() {
        $(".ah-words-wrapper").html("Digital")
    }, 550)
}), $(".footer-form").parsley(), $("#newsletter-form").parsley(), $(".footer-form").on("submit", function(e) {
    $(".footer-form").delay(500).fadeTo("medium", 0), $(".footer-form-success-message").delay(1e3).fadeTo("medium", 1), $(".footer-form-success-message").delay(6e3).fadeTo("medium", 0), $(".footer-form").delay(6500).fadeTo("medium", 1)
}); */


// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------
// --- SCROLL ---
// -------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------------------------------

// $(window).scroll(function () {
//   var scrollTop = $(window).scrollTop()
//   if (scrollTop > 200) {
//     $("body").addClass("scroll");
//   } else {
//     $("body").removeClass("scroll");
//   }
// });

$(document).ready(function () {

  $('section:not(.banner,.latest-card-section,.footer-form-section,.full-width-footer-section)').addClass('animatedParent animateOnce');
  $('section:not(.banner,.latest-card-section,.footer-form-section,.full-width-footer-section)>div').addClass('animated fadeInDownShort delay-200');

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- DATA POINT CAROUSEL ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  $('.data-point-section').each(function() {

    var owlCarouselTabletItemNumber = $('.data-point-section').data('data-points-per-slide');  

    $('.owl-carousel').owlCarousel({
      stagePadding: 45,
      margin: 15,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: owlCarouselTabletItemNumber
        }
      }
    });

  });

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- MOBILE MENU OVERLAY ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  $('.site-mobile-header__menu-trigger').on('click', function (e) {

    $('body').addClass('no-scroll');

    $('.mobile-menu-overlay').fadeIn();

    e.preventDefault();

  });

  $('.mobile-menu-overlay__close').on('click', function (e) {

    $('body').removeClass('no-scroll');

    $('.mobile-menu-overlay').fadeOut();

    $('.mobile-menu-overlay__nav .mobile-menu-overlay__nav').slideUp();

    e.preventDefault();

  });

  // click outside
  $('.mobile-menu-overlay__nav-item--parent > .mobile-menu-overlay__nav-link').on('click touchend', function (e) {

    e.preventDefault();

    $(this).closest('.mobile-menu-overlay__nav-item--parent').find('.mobile-menu-overlay__nav').slideToggle();

  });

  // dropdown menu - new 25/2/2020
  $('.js-dropdown-menu-control').on('click', function (e) {

    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      $('body').toggleClass('scroll-lock');
    }

    // remove active class from sibling links
    $(this).siblings().removeClass('js-dropdown-menu-control-active');

    // toggle active link class
    $(this).toggleClass('js-dropdown-menu-control-active');

    // remove open class from all sibling dropdown menus
    $(this).siblings().find('.js-dropdown-menu').removeClass('js-dropdown-menu-active');

    // toggle open class
    $(this).find('.js-dropdown-menu').toggleClass('js-dropdown-menu-active');

    // change aria hidden of all sibling dropdown menus to true
    $(this).siblings().find('.js-dropdown-menu').attr('aria-hidden', 'true');

    // change aria hidden to false
    $(this).find('.js-dropdown-menu').attr('aria-hidden', 'false');

    // stop default action of link
    // exclude dropdown menu from click event that will remove active and open classes, see below
    e.preventDefault();
    e.stopPropagation();

    // exclude dropdown menu from click event that will remove active and open classes, see below
    $('.js-dropdown-menu').on('click', function (e) {

      e.stopPropagation();

    });

    // call closeDesktopDropdownMenu function on clicking on body when dropdown is open
    $('body').on('click', function () {

      closeDesktopDropdownMenu();

    });

  });

  // remove active and open classes from all dropdown menus when clicking on the body
  // change aria hidden to true when clicking outside dropdown menu
  function closeDesktopDropdownMenu() {

    $('body').removeClass('scroll-lock');

    $('.js-dropdown-menu-control').removeClass('js-dropdown-menu-control-active');

    $('.js-dropdown-menu').removeClass('js-dropdown-menu-active');

    $('.js-dropdown-menu').attr('aria-hidden', 'true');

  }

  // detect when nav link is focused on using the tab key
  $(window).keyup(function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 9 && $('.js-dropdown-menu-control .site-header__nav-bottom-link:focus').length) {

      // remove active class from sibling links
      $(e.target).closest('.js-dropdown-menu-control').siblings().removeClass('js-dropdown-menu-control-active');

      // toggle active link class
      $(e.target).closest('.js-dropdown-menu-control').addClass('js-dropdown-menu-control-active');

      // remove open class from all sibling dropdown menus
      $(e.target).closest('.js-dropdown-menu-control').siblings().find('.js-dropdown-menu').removeClass('c-dropdown-menu-active');

      // toggle open class
      $(e.target).closest('.js-dropdown-menu-control').find('.js-dropdown-menu').toggleClass('js-dropdown-menu-active');

    }
  });

  // remove active class from primary nav links when focused out of nav
  $('.site-header__nav-bottom-item:last-child').on('focusout', function () {

    $('.js-dropdown-menu-control').removeClass('js-dropdown-menu-control-active');
    $('.js-dropdown-menu').removeClass('js-dropdown-menu-active');

  });

  // old dropdown
  /* $(".site-header__nav-bottom-item--parent > .site-header__nav-bottom-link").on("click", function (e) {

    e.preventDefault();

    $(this).parent().toggleClass('open');


    $(window).on("click", function (e) {

      if ($('.site-header__sub-nav').has(e.target).length == 0 && !$('.site-header__nav-bottom-item--parent > .site-header__nav-bottom-link').is(e.target)) {

        $(".site-header__sub-nav").parent().removeClass('open');

      }

    });

  }); */

  /* if (window.matchMedia('screen and (max-width: 768px)').matches) {

    $(".site-mobile-header__nav-item--parent > .site-mobile-header__nav-link").on("click", function (e) {

      e.preventDefault();

      $(this).parent().toggleClass('open');

      $(window).on("click", function (e) {

        if ($('.site-header__sub-nav').has(e.target).length == 0 && !$('.site-mobile-header__nav-item--parent > .site-mobile-header__nav-link').is(e.target)) {

          $(".site-header__sub-nav").parent().removeClass('open');

        }

      });

    });

  } */

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- PICTURE ELEMENT HTML5 SHIV ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  document.createElement('picture');

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- IMAGE TEXT BLOCK DESIGN PATTERN GRID ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  $('.image-text-block-design-pattern-grid').each(function (e) {

    var patternCount = $(this).children().length;

    if (patternCount <= 3) {

      $(this).addClass('image-text-block-design-pattern-grid--single-row');

    }

  });

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- CARD GRID ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  $('.card-grid').each(function (e) {

    var cardCount = $(this).children().length;

    if (cardCount <= 3) {

      $(this).addClass('card-grid--single-row');

    }

  });

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- TABS ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  // hide tabgroup content
  $('.tabgroup > .tabgroup__content-wrapper').hide();

  // show first tabgroup content
  $('.tabgroup > .tabgroup__content-wrapper:first-of-type').show();

  // add active class to first link
  $('.tabs__link').eq(0).addClass('active');

  $('.tabs__link').click(function (e) {

    // remove active class from all links
    $('.tabs__link').removeClass('active');

    // add active class to clicked link
    $(this).addClass('active');

    // hide all content
    $('.tabgroup__content-wrapper').hide();

    // show selected content
    $('.tabgroup__content-wrapper[id*=' + $(this).attr('href') + ']').show();

    e.preventDefault();

  });

  // number tab link hrefs in order
	/*var tabCount = 1;
	$('.tabs__link').each(function() {
		$(this).attr('href', function (index) {
			return '#tab' + tabCount;
		});
		tabCount++;
	});*/

  // number tab content wrapper ids in order
	/*var tabContentCount = 1;
	$('.tabgroup__content-wrapper').each(function() {
		$(this).attr('id', function (index) {
			return '#tab' + tabContentCount;
		});
		tabContentCount++;
	});*/

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- TABS ACCORDION ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  $('.accordion-trigger').click(function (e) {
    // uncomment below line to enable one tab at a time
    // $(this).closest('li').siblings().find('.accordion-trigger').removeClass('open').next('.accordion-content').slideUp(250);
    $(this).toggleClass('open').next('.accordion-content').slideToggle(250);
    e.preventDefault();
  });

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- PUBLISHED TIME ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  $('.card__author-name').each(function () {
    today = new Date();
    past = new Date($(this).find('.published-date').html());
    $time_ago = $(this).find('.time-ago');

    function calcDate(date1, date2) {
      var diff = Math.floor(date1.getTime() - date2.getTime());
      var day = 1000 * 60 * 60 * 24;
      var days = Math.floor(diff / day);
      var weeks = Math.floor(days / 7);
      var months = Math.floor(days / 31);
      var years = Math.floor(months / 12);

      if (days == 0) {
        $time_ago.html('today');
      }

      if ((days == 1) && (weeks < 1) && (months < 1) && (years < 1)) {
        $time_ago.html('yesterday');
      }

      if ((days > 1) && (weeks < 1) && (months < 1) && (years < 1)) {
        $time_ago.html(days + ' days ago');
      }

      if ((weeks >= 1) && (months < 1) && (years < 1)) {
        $time_ago.html(weeks + ' week ago');
      }

      if ((weeks >= 2) && (months < 1) && (years < 1)) {
        $time_ago.html(weeks + ' weeks ago');
      }

      if ((weeks >= 4) && (months < 2) && (years < 1)) {
        $time_ago.html('last month');
      }

      if ((months >= 2) && (years < 1)) {
        $time_ago.html(months + ' months ago');
      }

      if (years == 1) {
        $time_ago.html('last year');
      }

      if (years > 1) {
        $time_ago.html(years + ' years ago');
      }
    }

    calcDate(today, past);

  });

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- LINK RULES ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  // Forces PDF links to open in new tab
  $('a[href$=".pdf"]').attr('target', '_blank');

  // force external links that do not have the site base url to open in a new tab
  var baseUrl = document.location.origin;
  $('a[href^="http://"], a[href^="https://"]').not('a[href^="' + baseUrl + '"]').attr('target', '_blank');

  // Adds noopener noreferrer to any link with target="_blank" to avoid the window.opener phising vulnerablity
  $('a[target="_blank"]').attr('rel', 'noopener noreferrer');

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // --- COOKIES ---
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  // checkCookie();

  // function checkCookie() {
  //   var consent = getCookie("cookies_consent");
  //   if (consent == null || consent == "" || consent == undefined) {
  //     $('#cookie-note').show();
  //   }
  // }

  // function setCookie(c_name, value, exdays) {
  //   var exdate = new Date();
  //   exdate.setDate(exdate.getDate() + exdays);
  //   var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
  //   document.cookie = c_name + "=" + c_value + ";path=/";
  //   $('#cookie-note').slideUp('slow');
  // }

  // function getCookie(c_name) {
  //   var i, x, y, ARRcookies = document.cookie.split(";");
  //   for (i = 0; i < ARRcookies.length; i++) {
  //     x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
  //     y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
  //     x = x.replace(/^\s+|\s+$/g, "");
  //     if (x == c_name) {
  //       return unescape(y);
  //     }
  //   }
  // }

  // $('#cookie-accept').click(function (e) {
  //   setCookie("cookies_consent", 1, 30);
  //   e.preventDefault();
  // });

});
